<template>
  <div class="reviewedByMe" style="text-align: left;">
    <van-nav-bar
        title="待我审核"
        left-text="返回"
        left-arrow
        @click-left="onReturn"
    />

    <van-list
        v-model="loading"
        v-if="!isDataEmpty"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <van-cell v-for="(item,index) in list" :key="index" :title="item.createTime" :icon="icon_todo_date" :is-link="item.taskType != 1 && item.taskType != 4" :to="item.taskType != 1 && item.taskType != 4 && item.taskType != 9 && item.taskType != 10?'orderDetail?orderNo='+item.taskId:''">
        <template #label>
          <van-icon :name="icon_huanhuo" v-if="item.orderType == 4 || item.orderType == 5" />
          <span style="font-size: 18px;color: #565555;">{{item.title}}</span>
          <van-divider style="margin: 10px 0;" />
          <span style="font-size: 15px;line-height: 30px;">{{item.taskId}}</span>
          <van-button plain type="info" size="small" style="float: right;width:25%;" @click="approvePass(item.id, item.taskType);">通过</van-button>
          <van-button plain type="warning" size="small" style="float: right;width:25%;margin-right: 12px;" @click="reject(item.id, item.taskType);">驳回</van-button>
        </template>
      </van-cell>
    </van-list>

    <van-empty description="没有数据哦" v-if="isDataEmpty" />

    <van-dialog class="passRemarkDialog" v-model="passRemarkDialog4Confirm" title="选择通过理由" show-cancel-button @confirm="confirmApprovePassSubmit">
      <van-radio-group v-model="passRemark">
        <van-cell-group>
          <van-cell title="自愿申请" clickable @click="passRemark = '自愿申请';otherRemarkFlag = false;otherRemark = '';">
            <template #right-icon>
              <van-radio name="自愿申请" />
            </template>
          </van-cell>
          <van-cell title="内部推荐" clickable @click="passRemark = '内部推荐';otherRemarkFlag = false;otherRemark = '';">
            <template #right-icon>
              <van-radio name="内部推荐" />
            </template>
          </van-cell>
          <van-cell title="区域经理自拓" clickable @click="passRemark = '区域经理自拓';otherRemarkFlag = false;otherRemark = '';">
            <template #right-icon>
              <van-radio name="区域经理自拓" />
            </template>
          </van-cell>
          <van-cell title="其他" clickable @click="passRemark = '其他';otherRemarkFlag = true;otherRemark = '';">
            <template #right-icon>
              <van-radio name="其他" />
            </template>
          </van-cell>
          <van-field v-if="otherRemarkFlag" v-model="otherRemark" label="" placeholder="请输入其他理由" />
        </van-cell-group>
      </van-radio-group>
    </van-dialog>

    <van-dialog class="rejectRemarkDialog" v-model="rejectRemarkDialog4Confirm" title="选择拒绝理由" show-cancel-button @confirm="confirmRejectSubmit">
      <van-radio-group v-model="rejectRemark">
        <van-cell-group>
          <van-cell title="联系多次未回复" clickable @click="rejectRemark = '联系多次未回复';otherRemarkFlag = false;otherRemark = '';">
            <template #right-icon>
              <van-radio name="联系多次未回复" />
            </template>
          </van-cell>
          <van-cell title="核实信息不符" clickable @click="rejectRemark = '核实信息不符';otherRemarkFlag = false;otherRemark = '';">
            <template #right-icon>
              <van-radio name="核实信息不符" />
            </template>
          </van-cell>
          <van-cell title="体彩政策不允许" clickable @click="rejectRemark = '体彩政策不允许';otherRemarkFlag = false;otherRemark = '';">
            <template #right-icon>
              <van-radio name="体彩政策不允许" />
            </template>
          </van-cell>
          <van-cell title="其他" clickable @click="rejectRemark = '其他';otherRemarkFlag = true;otherRemark = '';">
            <template #right-icon>
              <van-radio name="其他" />
            </template>
          </van-cell>
          <van-field v-if="otherRemarkFlag" v-model="otherRemark" label="" placeholder="请输入其他理由" />
        </van-cell-group>
      </van-radio-group>
    </van-dialog>
  </div>
</template>

<script>
import { myTaskList, approvePass, reject } from "@/api/order";
import icon_todo_date from "@/assets/icon_todo_date.png";
import icon_huanhuo  from "@/assets/icon_huanhuo.png";
import { Toast } from 'vant';

export default {
  name: "ReviewedByMe",
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      icon_todo_date: icon_todo_date,
      icon_huanhuo: icon_huanhuo,
      pageNo: 0,
      pageSize: 10,
      isDataEmpty: false,
      passRemarkDialog4Confirm: false,
      passRemark: '自愿申请',
      rejectRemarkDialog4Confirm: false,
      rejectRemark: '联系多次未回复',
      otherRemarkFlag: false,
      otherRemark: '',
      selectedTaskId: '',
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
    },
    onLoad() {
      this.pageNo++;
      myTaskList(1, this.pageNo, this.pageSize).then(response => {
        console.log(response.data);
        let data = response.data;
        // 加载状态结束
        this.loading = false;
        // 没有数据
        if(data.total == 0) {
          this.isDataEmpty = true;
          this.finished = true;
        } else {
          for(let i in data.records) {
            let item = data.records[i];
            console.log(item);
            this.list.push({id:item.id, taskId:item.taskId, createTime: item.createTime.replace('T',' '), title: item.title, taskType: item.taskType, orderType: item.orderType});
          }
          if(data.current == data.pages) {
            // 数据全部加载完成
            this.finished = true;
          }
        }
      });
    },
    approvePass(id, taskType) {
      event.stopPropagation();
      console.log(id, taskType);
      if(taskType == 1) {
        this.selectedTaskId = id;
        this.passRemark = '自愿申请';
        this.otherRemarkFlag = false;
        this.otherRemark = '';
        this.passRemarkDialog4Confirm = true;
      } else {
        approvePass(id, '').then(r => {
          console.log(r);
          Toast.success('已审核通过');
          this.list = [];
          this.pageNo = 0;
          this.isDataEmpty = false;
          this.loading = false;
          this.finished = false;
        });
      }
    },
    confirmApprovePassSubmit() {
      // 判断理由是否填写
      if(this.passRemark == '其他') {
        if(!this.otherRemark) {
          Toast.fail("未输入其他理由.");
          return false;
        } else {
          this.passRemark = this.otherRemark;
        }
      }
      approvePass(this.selectedTaskId, this.passRemark).then(r => {
        console.log(r);
        Toast.success('已审核通过');
        this.list = [];
        this.pageNo = 0;
        this.isDataEmpty = false;
        this.loading = false;
        this.finished = false;
      });
    },
    reject(id, taskType) {
      event.stopPropagation();
      if(taskType == 1) {
        this.selectedTaskId = id;
        this.rejectRemark = '联系多次未回复';
        this.otherRemarkFlag = false;
        this.otherRemark = '';
        this.rejectRemarkDialog4Confirm = true;
      } else {
        reject(id, '').then(r => {
          console.log(r);
          Toast.success('已完成驳回');
          this.list = [];
          this.pageNo = 0;
          this.isDataEmpty = false;
          this.loading = false;
          this.finished = false;
        });
      }
    },
    confirmRejectSubmit() {
      // 判断理由是否填写
      if(this.rejectRemark == '其他') {
        if(!this.otherRemark) {
          Toast.fail("未输入其他理由.");
          return false;
        } else {
          this.rejectRemark = this.otherRemark;
        }
      }
      reject(this.selectedTaskId, this.rejectRemark).then(r => {
        console.log(r);
        Toast.success('已完成驳回');
        this.list = [];
        this.pageNo = 0;
        this.isDataEmpty = false;
        this.loading = false;
        this.finished = false;
      });
    },
    onReturn() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
.reviewedByMe {
  background: #f5f5f5;
}
.van-cell__title > span {
 color:#a5a0a0;
}
.van-cell {
  font-size: 16px;
  margin-bottom: 10px;
}
.van-icon__image {
  width: 1.2em;
  height: 1.2em;
}
.van-button--small {
  font-size: 14px;
}
</style>
